// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-company-index-tsx": () => import("./../../../src/pages/company/index.tsx" /* webpackChunkName: "component---src-pages-company-index-tsx" */),
  "component---src-pages-contact-complete-tsx": () => import("./../../../src/pages/contact/complete.tsx" /* webpackChunkName: "component---src-pages-contact-complete-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-recruit-entry-tsx": () => import("./../../../src/pages/recruit/entry.tsx" /* webpackChunkName: "component---src-pages-recruit-entry-tsx" */),
  "component---src-pages-service-index-tsx": () => import("./../../../src/pages/service/index.tsx" /* webpackChunkName: "component---src-pages-service-index-tsx" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/IndexPage.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-privacy-page-tsx": () => import("./../../../src/templates/PrivacyPage.tsx" /* webpackChunkName: "component---src-templates-privacy-page-tsx" */),
  "component---src-templates-recruit-page-tsx": () => import("./../../../src/templates/RecruitPage.tsx" /* webpackChunkName: "component---src-templates-recruit-page-tsx" */),
  "component---src-templates-service-detail-page-tsx": () => import("./../../../src/templates/ServiceDetailPage.tsx" /* webpackChunkName: "component---src-templates-service-detail-page-tsx" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

